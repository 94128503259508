import { GuideType, InteractiveComponents } from '../../../core/enums';
import { ScreenTaskControllerTypes } from '../../../core/navigation/ScreenTaskControllerTypes';
import {
    GLOBAL_MOUSE_TRAP,
    channelOptionsOverlayApplyFilter,
    showGuideOptionsOverlay,
} from '../../overlay/channeloptionsoverlay/channeloptionsoverlay.reducers';
import {
    hideOverlayDialog,
    onKeyDownOverlayDialog,
    onKeyLeftOverlayDialog,
    onKeyRightOverlayDialog,
    onKeyUpOverlayDialog,
    onMouseOutOverlayDialogOperations,
    onMouseOverOverlayDialogOperations,
} from '../../overlaydialog/overlaydialog.reducers';
import { showGuideScreen } from '../../screens/screens.reducers';

export const onKeyOkEmptyGuide =
    (guideType: GuideType = GuideType.GRID_GUIDE) =>
    (dispatch: Function, getState: Function) => {
        const { verticalPosition } = getState().overlayHighlight;

        if (guideType === GuideType.ONE_LINE_GUIDE && verticalPosition === 0) {
            dispatch(showGuideScreen());
            return;
        }

        dispatch(channelOptionsOverlayApplyFilter());
        if (guideType === GuideType.GRID_GUIDE) {
            dispatch(showGuideScreen());
        }
    };

export const onLongKeyOkEmptyGuide =
    (guideType: GuideType = GuideType.GRID_GUIDE) =>
    (dispatch: Function) => {
        dispatch(showGuideOptionsOverlay(guideType));
    };

export const onKeyUpEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyUpOverlayDialog());
};

export const onKeyDownEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyDownOverlayDialog());
};

export const onKeyRightEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyRightOverlayDialog());
};

export const onKeyLeftEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onKeyLeftOverlayDialog());
};

export const onKeyBackEmptyGuide = () => (dispatch: Function) => {
    dispatch(hideOverlayDialog());
};

export const onMouseOverEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOverOverlayDialogOperations());
};

export const onMouseOutEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { screenTaskController } = getState().screens;
    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }
    dispatch(onMouseOutOverlayDialogOperations());
};

export const onMouseClickEmptyGuide = () => (dispatch: Function, getState: Function) => {
    const { activeComponent } = getState().interactiveLayer;
    const { value } = getState().inputFactory;
    const { screenTaskController } = getState().screens;

    if (screenTaskController !== ScreenTaskControllerTypes.CHANNEL_OPTIONS_OVERLAY) {
        return;
    }

    if (value === GLOBAL_MOUSE_TRAP) {
        dispatch(hideOverlayDialog());
        return;
    }

    if (activeComponent === InteractiveComponents.WEST) dispatch(onKeyLeftOverlayDialog());
    else if (activeComponent === InteractiveComponents.EAST) dispatch(onKeyRightOverlayDialog());
    else dispatch(onKeyOkEmptyGuide());
};
